Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";

exports.beforeLoginMenus = [
  {
    "id": 1,
    "name": "HOME",
    "path": "",
  },
  {
    "id": 2,
    "name": "CIRCULAR MARKETPLACE",
    "path": "Catalogue",
  },
  {
    "id": 3,
    "name": "ABOUT US",
    "path": "",
  },
  {
    "id": 4,
    "name": "HOW IT WORKS",
    "path": "",
  },
  {
    "id": 5,
    "name": "WHITE PAPER",
    "path": "",
  },
  {
    "id": 6,
    "name": "PRICING",
    "path": "",
  },
  {
    "id": 7,
    "name": "ESG",
    "path": "",
  },
]

exports.contactText = "CONTACT";
exports.contactRoute = "Contactus";
exports.loginText = "LOGIN";
exports.loginRoute = "EmailAccountLoginBlock";
exports.signupText = "SIGN UP";
exports.signupRoute = "EmailAccountRegistration";

exports.termsOfUse = "TERMS OF USE";
exports.privacyText = "PRIVACY POLICY";
exports.logoTxt1 = "© 2024 PROCURE";
exports.logoTxt2 = "CIRCULAR.";
exports.homepath = "";
exports.facebookLink = "";
exports.twitterlink = "";
exports.youtubeLink = "";
exports.instagramLink = "";
exports.linkdinLink = "";

exports.buyerText = "BUYER";
exports.supplierText = "SUPPLIER";

// Customizable Area End