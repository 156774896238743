import React, { Component } from "react";

import {
  Box,
  Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { colors } from "../../blocks/utilities/src/Colors";
const FacebookIcon = require("../src/icons-nav/facebook_icon.svg");
const TwitterIcon = require("../src/icons-nav/twitter_icon.svg");
const YoutubeIcon = require("../src/icons-nav/youtube_icon.svg");
const InstagramIcon = require("../src/icons-nav/instagram_icon.svg");
const LinkdinIcon = require("../src/icons-nav/linkdin_icon.svg");
const whiteLogo = require("../src/icons-nav/white_logo.svg");
const ambionLogo = require("../src/icons-nav/AmbionLogo.svg");
const dotIcon = require("../src/icons-nav/dotIcon.svg");
interface Props {
  navigation?: any,
  configJSON: any,
  onHandleNavigation: any,
}
interface S {
}
class Footer extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
  }



  render() {
    const { configJSON, onHandleNavigation } = this.props;
    return (
      <StyledFooter>
        <FooterWrapper>
          <Box className="innerWrapper">
            <FirstGirdItem>
              <LogoContainer>
                <img src={ambionLogo} onClick={() => onHandleNavigation(configJSON.homepath)} />
                <img src={whiteLogo} onClick={() => onHandleNavigation(configJSON.homepath)} />
              </LogoContainer>

              <SocialIconContainer>
                <Typography
                  component="a"
                  href={configJSON.facebookLink}
                  target="_blank"
                >
                  <Box
                    component="img"
                    src={FacebookIcon}
                    alt="Facebook Icon"
                  />
                </Typography>

                <Typography
                  component="a"
                  href={configJSON.twitterlink}
                  target="_blank"
                >
                  <Box
                    component="img"
                    src={TwitterIcon}
                  />
                </Typography>

                <Typography
                  component="a"
                  href={configJSON.youtubeLink}
                  target="_blank"
                >
                  <Box
                    component="img"
                    src={YoutubeIcon}
                  />
                </Typography>

                <Typography
                  component="a"
                  href={configJSON.instagramLink}
                  target="_blank"
                >
                  <Box
                    component="img"
                    src={InstagramIcon}
                  />
                </Typography>

                <Typography
                  component="a"
                  href={configJSON.linkdinLink}
                  target="_blank"
                >
                  <Box
                    component="img"
                    src={LinkdinIcon}
                  />
                </Typography>
              </SocialIconContainer>
            </FirstGirdItem>
            <SecondGridItem>
              <Typography onClick={() => onHandleNavigation("TermsConditions")} className="termsTypographyLink" variant="body2">
                {configJSON.termsOfUse}
              </Typography>
              <Typography className="termsTypographyLink" variant="body2">
                {configJSON.privacyText}
              </Typography>
              <Box className="logoTextWrapper">
                <Typography className="logoText1" variant="body2">
                  {configJSON.logoTxt1}
                </Typography>
                <Typography className="logoText2" variant="body2">
                  {configJSON.logoTxt2}
                </Typography>
              </Box>
            </SecondGridItem>
          </Box>
          <Box className="dotWrapper">

            {Array.from({ length: 12 }, (_, i) => i + 1).map((number) => (
              <img src={dotIcon} />
            ))}
          </Box>
        </FooterWrapper>
      </StyledFooter>
    );
  }
}

const StyledFooter = styled(Box)(({ theme }) => ({
  padding: '0 16px',
  backgroundColor: colors().ultraDarkGreen,
  textAlign: 'center',
  minHeight: "135px",
  height: "auto",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@media (max-width: 1015px)': {
    minHeight: "232px",
  },
  '@media (max-width: 522px)': {
    minHeight: "311px",
  },
  '@media (max-width: 260px)': {
    padding: "10px",
  },
}));

const FooterWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  maxWidth: "1600px",
  width: '100%',
  position: 'relative',
  "& .innerWrapper": {
    display: 'flex',
    gap: "15px",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: 'center',
    width: "100%",
    '@media (max-width: 1015px)': {
      gap: "40px"
    },
    '@media (max-width: 522px)': {
      gap: "31px",
    }
  },
  "& .dotWrapper": {
    display: 'flex',
    position: 'absolute',
    bottom: "0",
    right: "0",
    alignItems: 'center',
    gap: "37px",
    flexWrap: "nowrap",
    overflow: "hidden",
    height: "45px",
  }
}));

const FirstGirdItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  flexWrap: "wrap",
  gap: "10px",
  width: "50%",
  '@media (max-width: 1015px)': {
    width: "100%",
  },
  '@media (max-width: 522px)': {
    gap: "42px",
  }
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: "wrap",
  cursor: "pointer",
}));

const SocialIconContainer = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: "11px",

  "& img:hover": {
    transition: "all 0.2s ease-in",
    filter: 'brightness(0) saturate(100%) invert(49%) sepia(68%) saturate(409%) hue-rotate(1deg) brightness(96%) contrast(101%)'
  },
  '@media (max-width: 1015px)': {
    gap: "18px",
  },
  '@media (max-width: 295px)': {
    gap: "5px",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: 'space-between',
  },
}));

const SecondGridItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  gap: "36px",
  flexWrap: "wrap",
  flex: 1,
  '@media (max-width: 1015px)': {
    width: "100%",
  },

  '@media (max-width: 289px)': {
    gap: "8px"
  },

  "& .termsTypographyLink": {
    cursor: "pointer",
    color: colors().white,
    lineHeight: "20px",
    "&:hover": {
      color: colors().darkGold
    },
    fontSize: "14px",
    fontWeight: '390',
    wordWrap: 'break-word'
  },
  "& .logoTextWrapper": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (min-width: 290px) and (max-width: 522px)': {
      marginTop: "-28px"
    },
  },
  "& .logoText1": {
    cursor: "pointer",
    color: colors().white,
    lineHeight: "20px",
    fontSize: "14px",
    fontWeight: '390',
    wordWrap: 'break-word'
  },
  "& .logoText2": {
    cursor: "pointer",
    color: colors().lightGreen,
    lineHeight: "20px",
    fontSize: "14px",
    fontWeight: '390',
    wordWrap: 'break-word'
  },
}));

export default (Footer)