import React, { Component } from "react";

import {
  Box,
  Typography,
  Avatar,
  Switch,
  Divider
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { colors } from "../../blocks/utilities/src/Colors";

import { NavigationSidebarDataFirst, NavigationSidebarDataSecond, NavigationSidebarDataThird } from './NavigationSidebarData';
import { logoutIcon } from "../../blocks/navigationmenu/src/assets";

interface Props {
  navigation?: any,
  configJSON: any,
  onHandleNavigation: any,
  onHandleBuyerSupplier: any,
  isSupplier: boolean,
  activePath: string
  onHandleLogout: any
}
interface S {
}

class SidebarAfterLogin extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const { configJSON, isSupplier, activePath, onHandleBuyerSupplier, onHandleNavigation, onHandleLogout } = this.props;
    return (
      <MainWrapper>
        <SidebarContent>
          <ul style={navSidebarMenu.sidebarItems}>
            {
              NavigationSidebarDataFirst.map((value, key) => {
                return (
                  <SidebarContentItem className={`${(value.link && activePath.includes(value.link)) && "activePath"}`} onClick={() => onHandleNavigation(value.link, value.secondPath)} key={key}>
                    <div className="sidebarIcon"><img src={value.icon} /></div>
                    <div className={"title" + value.name} >{value.title}</div>
                  </SidebarContentItem>
                )
              })
            }
          </ul>
          <Divider sx={{ backgroundColor: "#EDF0F2" }} />
          <ul style={navSidebarMenu.sidebarItems}>
            {
              NavigationSidebarDataSecond.map((value, key) => {
                return (
                  <SidebarContentItem className={`${(value.link && activePath.includes(value.link)) && "activePath"}`} onClick={() => onHandleNavigation(value.link, value.secondPath)} key={key}>
                    <div className="sidebarIcon"><img src={value.icon} /></div>
                    <div >{value.title}</div>
                  </SidebarContentItem>
                )
              })
            }
          </ul>
          <Divider sx={{ backgroundColor: "#EDF0F2" }} />
          <ul style={{ ...navSidebarMenu.sidebarItems, border: 'none' }}>
            {
              NavigationSidebarDataThird.map((value, key) => {
                return (
                  <SidebarContentItem className={`${(value.link && activePath.includes(value.link)) && "activePath"}`} onClick={() => onHandleNavigation(value.link, value.secondPath)} key={key}>
                    <div className="sidebarIcon">
                      <img src={value.icon} />
                    </div>
                    <div
                      className={value.name + value.link}
                      key={key}
                    >
                      {value.title}
                    </div>
                  </SidebarContentItem>
                )
              })
            }
               <SidebarContentItem onClick={() => onHandleLogout()}>
                    <div className="sidebarIcon">
                      <img src={logoutIcon} />
                    </div>
                    <div
                    >
                      Log out
                    </div>
                  </SidebarContentItem>
          </ul>

          <Box className="sb-userinfo">
            <Box className="sb-userinfo-m">
              <Box className="sb-userinfo-m-img-container">
                <Avatar className="sb-userinfo-m-img-avatar" alt="Jenny Wilson">JW</Avatar>
              </Box>
              <Typography className="sb-userinfo-m-name">Jenny Wilson</Typography>
              <Typography className="sb-userinfo-m-email">jwilson@procurenarotaris.com</Typography>
              <Box className="sb-userinfo-b">
                <Typography className={`${isSupplier ? "sb-userinfo-b-supplier" : "sb-userinfo-b-buyer"}`}>{configJSON.buyerText}</Typography>
                <AntSwitch
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={(event) => onHandleBuyerSupplier(event.target.checked)}
                />
                <Typography className={`${!isSupplier ? "sb-userinfo-b-supplier" : "sb-userinfo-b-buyer"}`}>{configJSON.supplierText}</Typography>
              </Box>
            </Box>
          </Box>
        </SidebarContent>
        <SidebarDevider>
          <div className="divider"></div>
        </SidebarDevider>
      </MainWrapper>
    );
  }
}

const navSidebarMenu = {
  body: {
    margin: 0,
    padding: 0,
  },

  parentDiv: {
    display: "flex",
    '@media (max-width: 1024px)': {
      display: "none",
    },
  },

  sidebarItems: {
    height: "auto",
    padding: "0px",
    with: "auto",
  } as React.CSSProperties,
};

const MainWrapper = styled('div')({
  display: "flex",
    '@media (max-width: 1024px)': {
      display: "none",
    },
});

const SidebarContent = styled('div')({
  width: "max-content",
  minWidth: "170px",
  height: "auto",
  margin: "10px 7px 0px 30px",
  opacity: "0px",
  "& .sb-userinfo": {
    width: "100%",
    height: "146px",
    margin: "93px 0px 0px 0px",

  },
  "& .sb-userinfo-m": {
    height: "auto",
    margin: "93px 0px 0px 0px",
    border: "1px solid #D9D9D9",
    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2px"
  },
  "& .sb-userinfo-m-img-container": {
    width: "43px",
    height: "43px",
    padding: "2px",
    backgroundColor: colors().white,
    borderRadius: "50%",
    border: `1px solid ${colors().darkGold}`,
    fontSize: "12px",
    marginBottom: "10px",
    marginTop: "-18px",
  },
  "& .sb-userinfo-m-img-avatar": {
    width: "100%",
    height: "100%",
    backgroundColor: colors().lightGold,
    color: colors().darkGold,
  },
  "& .sb-userinfo-m-name": {
    textAlign: "center",
    fontSize: "12px",
    lineHeight: "17px",
    fontWeight: "420",
    color: colors().ultraDarkGreen,
    textTransform: 'uppercase',
    wordBreak: 'break-all'
  },
  "& .sb-userinfo-m-email": {
    marginTop: "2px",
    textAlign: "center",
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "300",
    color: colors().ultraDarkGreen,
    wordBreak: 'break-all',
    fontFamily: "garamond-premier-pro !important"
  },
  "& .sb-userinfo-b": {
    width: "100%",
    height: "41px",
    marginTop: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F9FAF9",
  },
  "& .sb-userinfo-b-buyer": {
    textAlign: "center",
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: "420",
    color: colors().ultraDarkGreen,
    textTransform: 'uppercase',
    marginTop: "5px",
    wordBreak: 'break-all'
  },
  "& .sb-userinfo-b-supplier": {
    textAlign: "center",
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: "420",
    color: "#ACACAC",
    textTransform: 'uppercase',
    marginTop: "5px",
    wordBreak: 'break-all'
  },
});
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 14,
  padding: 0,
  margin: "0 10px",
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors().darkGold,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: colors().darkGold,
    boxSizing: 'border-box',
  },
}));
const SidebarDevider = styled(Box)({
  height: "100%",
  width: "23px",
  borderTop: "1px solid #BA9553",
  alignItems: "center",
  justifyContent: "center",
  "& .divider": {
    borderRight: "1px solid #BA9553",
    height: "100%",
    width: "50%"
  },
});
const SidebarContentItem = styled('li')({
  cursor: "pointer",
  gap: "22px",
  height: "24px",
  opacity: "0px",
  padding: "2px 4px",
  listStyleType: "none",
  display: "flex",
  flexDirection: "row",
  fontSize: "12px",
  color: "#29224",
  alignItems: "center",
  justifyContent: "left",
  "&.activePath": {
    color: colors().darkGold,
    background: colors().lightGold,
    "& img": {
      filter: 'brightness(0) saturate(100%) invert(49%) sepia(68%) saturate(409%) hue-rotate(1deg) brightness(96%) contrast(101%)'
    },
  },

  "&:hover": {
    color: colors().darkGold,
    background: colors().lightGold,
    transition: "all 0.1s ease-in",
    "& img": {
      filter: 'brightness(0) saturate(100%) invert(49%) sepia(68%) saturate(409%) hue-rotate(1deg) brightness(96%) contrast(101%)'
    },
  },

  "& .sidebarIcon": {
    height: "20px",
    display: "grid",
    placeItems: "center",
  },
});

export default (SidebarAfterLogin)