import React, { Component } from "react";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

import {
  Button,
  Box,
  IconButton,
  Menu,
  Divider,
  MenuItem,
  Typography,
  Switch
} from "@mui/material";
import { Theme, styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { colors } from "../../blocks/utilities/src/Colors";
import { NavigationSidebarDataFirst, NavigationSidebarDataSecond, NavigationSidebarDataThird } from "./NavigationSidebarData";
import { logoutIcon } from "../../blocks/navigationmenu/src/assets";
const logo = require("../src/icons-nav/logo.png");
interface Props {
  navigation?: any,
  configJSON: any,
  token: string,
  activePath: string,
  onHandleLogout: any
}
interface S {
  anchorEl: null | HTMLElement;
  isSupplier: boolean;
}
export interface MenuItem1 {
  id: number;
  name: string;
  path: string;
}
class NavbarBeforeLogin extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
      isSupplier: false,
    };
  }

  handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  };

  handleBuyerSupplier = (isSupplier: boolean) => {
    this.setState({ isSupplier })
  };

  handleMenuNavigation = (path: string, dynamicPath?: string) => {
    if (path) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), path);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), dynamicPath);
      runEngine.sendMessage(message.id, message);
      this.handleMenuClose();
    }
  };

  render() {
    const { anchorEl, isSupplier } = this.state;
    const { configJSON, token, activePath } = this.props;
    const renderTabMenuBeforeLogin = () => {
      return (
        <>
          {configJSON.beforeLoginMenus.map((item: MenuItem1) => (
            <MobileMenuItemWrapper key={item.id} onClick={() => this.handleMenuNavigation(item.path)}>
              <MobileMenuItem selected={item.path === activePath} key={item.id}>
                {item.name}
              </MobileMenuItem>
            </MobileMenuItemWrapper>
          ))}
          <Divider sx={{ margin: "2px 0 8px", backgroundColor: "#EDF0F2" }} />
          <MobileMenuItemWrapper>
            <MobileMenuItem selected={configJSON.contactRoute === activePath} onClick={() => this.handleMenuNavigation(configJSON.contactRoute)}>
              {configJSON.contactText}
            </MobileMenuItem>
          </MobileMenuItemWrapper>
        </>
      )
    };

    const renderTabMenuAfterLogin = () => {
      return (
        <>
          {NavigationSidebarDataFirst.map((item) => (
            <MobileMenuItemWrapper key={item.name} onClick={() => this.handleMenuNavigation(item.link, item.secondPath)}>
              <MobileMenuItem selected={!!(item.link && activePath.includes(item.link))} key={item.name}>
                <div className={`sidebarIcon ${(item.link && activePath.includes(item.link)) && "selected"}`}><img src={item.icon} /></div>
                {item.title}
              </MobileMenuItem>
            </MobileMenuItemWrapper>
          ))}
          <Divider sx={{ margin: "2px 0", backgroundColor: "#EDF0F2" }} />
          {NavigationSidebarDataSecond.map((item) => (
            <MobileMenuItemWrapper key={item.name} onClick={() => this.handleMenuNavigation(item.link, item.secondPath)}>
              <MobileMenuItem selected={!!(item.link && activePath.includes(item.link))} key={item.name}>
                <div className={`sidebarIcon ${(item.link && activePath.includes(item.link)) && "selected"}`}><img src={item.icon} /></div>
                {item.title}
              </MobileMenuItem>
            </MobileMenuItemWrapper>
          ))}
          <Divider sx={{ margin: "2px 0", backgroundColor: "#EDF0F2" }} />
          {NavigationSidebarDataThird.map((item) => (
            <MobileMenuItemWrapper key={item.name} onClick={() => this.handleMenuNavigation(item.link, item.secondPath)}>
              <MobileMenuItem selected={!!(item.link && activePath.includes(item.link))} key={item.name}>
                <div className={`sidebarIcon ${(item.link && activePath.includes(item.link)) && "selected"}`}><img src={item.icon} /></div>
                {item.title}
              </MobileMenuItem>
            </MobileMenuItemWrapper>
          ))}
          <Divider sx={{ margin: "2px 0", backgroundColor: "#EDF0F2" }} />
          <MobileMenuItemWrapper>
            <Box className="sb-userinfo-b">
              <Typography className={`${isSupplier ? "sb-userinfo-b-supplier" : "sb-userinfo-b-buyer"}`}>{configJSON.buyerText}</Typography>
              <AntSwitch
                onChange={(event) => this.handleBuyerSupplier(event.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography className={`${!isSupplier ? "sb-userinfo-b-supplier" : "sb-userinfo-b-buyer"}`}>{configJSON.supplierText}</Typography>
            </Box>
          </MobileMenuItemWrapper>
        </>
      )
    };


    return (
      <OuterMainContainer>
        <Box sx={styles1.headerContainer}>
          <LogoContainer>
            <MenuIconBtn onClick={this.handleMenuOpen}>
              <MenuIcon />
            </MenuIconBtn>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              slotProps={{
                paper: {
                  style: {
                    padding: "0px 5px",
                  },
                },
              }}
              className="menuListContainer"
            >
              {token ? renderTabMenuAfterLogin() : renderTabMenuBeforeLogin()}
            </Menu>

            <img src={logo} />
          </LogoContainer>
          <Box sx={styles1.allMenuOuter}>
            {!token &&
              <Box sx={styles1.menuContainer}>
                <Box sx={styles1.navbar1}>
                  {
                    configJSON.beforeLoginMenus.map((menu: MenuItem1) => <StyledMenuItem selected={menu.path === activePath} key={menu.id} onClick={() => this.handleMenuNavigation(menu.path)} data-test-id="menuItem">{menu.name}</StyledMenuItem>)
                  }
                </Box>
                <Box sx={styles1.navbar2}>
                  <StyledMenuItem selected={configJSON.contactRoute === activePath} data-test-id="contactus" onClick={() => this.handleMenuNavigation(configJSON.contactRoute)}>{configJSON.contactText}</StyledMenuItem>
                </Box>
                <Box sx={styles1.navbar3}>
                  <LoginButton data-test-id="loginBtn" onClick={() => this.handleMenuNavigation(configJSON.loginRoute)}>
                    {configJSON.loginText}
                  </LoginButton>
                  <SignupButton data-test-id="signupBtn" onClick={() => this.handleMenuNavigation(configJSON.signupRoute)}>
                    {configJSON.signupText}
                  </SignupButton>
                </Box>
              </Box>
            }
          </Box>
        </Box>
      </OuterMainContainer>
    );
  }
}

const OuterMainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
}));

const StyledMenuItem = styled(MenuItem)(({ theme }: { theme: Theme }) => ({
  borderRadius: '10px',
  textTransform: 'none',
  fontSize: '16px',
  cursor: 'pointer',
  flexGrow: '1',
  flexShrink: '1',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  height: "100%",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.primary.contrastText,
  padding: '6px 12px',
  fontWeight: 420,

  '@media (max-width: 1465px)': {
    padding: '6px 10px',
  },
  '@media (max-width: 1433px)': {
    padding: '6px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '7px 27px',
  },

  '&.MuiButtonBase-root.MuiMenuItem-root': {
    color: theme.palette.text.primary,
  },

  '&.MuiButtonBase-root.MuiMenuItem-root:hover': {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.contrastText,
    fontWeight: 450,
  },
  "&.MuiButtonBase-root.Mui-selected": {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.secondary.main,
    fontWeight: 450,
  }
}));

const LoginButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  color: colors().blackText,
  borderRadius: "10px",
  textTransform: "none",
  fontSize: "16px",
  pointer: "cursor",
  height: "100%",
  padding: "10px 20px",
  minWidth: 80,
  fontWeight: 420,

  "&.MuiButtonBase-root.MuiButton-root": {
    color: colors().blackText,
  },
  "&.MuiButtonBase-root.MuiButton-root:hover": {
    color: colors().darkGold,
    fontWeight: 450,
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: "12px",
    padding: "10px",
    minWidth: "auto",
  },
}));
const SignupButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  color: colors().blackText,
  borderRadius: "10px",
  textTransform: "none",
  fontSize: "16px",
  pointer: "cursor",
  height: "inherit",
  fontWeight: 420,
  "&.MuiButtonBase-root.MuiButton-root": {
    color: colors().blackText,
    background: `linear-gradient(99.09deg, #D8C487 2.64%, ${colors().darkGold} 100%)`,
    whiteSpace: "nowrap",
    borderRadius: "0px",
  },
  "&.MuiButtonBase-root.MuiButton-root:hover": {
    color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: "12px",
    padding: "0",
    margin: "0",
  },
}));
const styles1 = {
  mainContainer: {
    height: "100vh",
    flexDirection: "column" as const,
    justifyContent: "space-between",

    display: "flex",
  },
  headerContainer: (theme: Theme) => ({
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
    color: colors().blackText,

    fontSize: "16px",
    margin: "21px 30px 14px",
    flexWrap: "wrap" as const,

    maxWidth: "calc(1600px - 60px)",
    width: "100%",

    '@media (max-width: 1024px)': {
      margin: "13px 24px 10px",
    },
    [theme.breakpoints.down('md')]: {
      margin: "13px 24px 10px",
    },
    [theme.breakpoints.down('sm')]: {
      margin: "10px 10px 10px 24px",
    },

  }),
  menuContainer: (theme: Theme) => ({
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
  }),
  allMenuOuter: {
    display: "flex",
    gap: "20px",
    flexWrap: "wrap" as const,
  },
  navbar1: (theme: Theme) => ({
    height: "35px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: "20px",
    flexWrap: 'wrap',
    border: "1px solid #D8C487",
    borderImageSource: "linear-gradient(99.09deg, #D8C487 2.64%, #B7862B 100%)",
    borderImageSlice: 1,
    opacity: "0px",
    overflow: "hidden",
    padding: "2px",
    '@media (max-width: 1600px)': {
      gap: "5px",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: "0",
    },
    '@media (max-width: 1024px)': {
      display: "none",
    },
  }),
  navbar2: (theme: Theme) => ({
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #D8C487",
    borderImageSource: "linear-gradient(99.09deg, #D8C487 2.64%, #B7862B 100%)",
    borderImageSlice: 1,
    marginTop: "20px",
    marginRight: "20px",
    padding: "2px",
    gap: "10px",
    opacity: "0px",
    [theme.breakpoints.down('md')]: {
      marginTop: "0",
    },
    '@media (max-width: 1369px)': {
      marginRight: "0px",
    },
    '@media (max-width: 1024px)': {
      display: "none",
    },
  }),
  navbar3: (theme: Theme) => ({
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #D8C487",
    borderImageSource: "linear-gradient(99.09deg, #D8C487 2.64%, #B7862B 100%)",
    borderImageSlice: 1,
    marginTop: "20px",
    opacity: "0px",
    padding: "2px",

    [theme.breakpoints.down('md')]: {
      marginTop: "0",
      height: "28px",
      padding: "2px"
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: "0",
      height: "24px",
      padding: "2px"
    },
  }),
};

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: "20px",
  marginRight: "20px",
  "& .menuListContainer": {
    display: "none",
    '@media (max-width: 1024px)': {
      display: "block",
    },
  },
  [theme.breakpoints.down('md')]: {
    marginTop: "0",
    height: "30px"
  },
  "& img": {
    height: "13px",
    [theme.breakpoints.between('sm', 'md')]: {
      height: "9px",
      width: "137px",
    },
    [theme.breakpoints.down('sm')]: {
      height: "6.72px",
      width: "103px",
    },
  },
}));

const MenuIconBtn = styled(IconButton)(({ theme }) => ({
  display: 'none',
  marginRight: "16px",
  padding: "0",
  '@media (max-width: 1024px)': {
    display: 'inline-flex',
  },
}));

const MobileMenuItem = styled(MenuItem)(({ theme }) => ({
  justifyContent: "start",
  width: "100%",
  borderRadius: "0px",
  fontSize: "14px",
  fontWeight: '390',
  lineHeight: "22px",
  wordWrap: 'break-word',
  padding: "9px 20px 9px 16px",
  "&.MuiButtonBase-root.MuiMenuItem-root:hover": {
    color: colors().darkGold,
    background: colors().lightGold,
    "& img": {
      filter: 'brightness(0) saturate(100%) invert(49%) sepia(68%) saturate(409%) hue-rotate(1deg) brightness(96%) contrast(101%)'
    },
  },
  "&.MuiButtonBase-root.Mui-selected": {
    color: colors().darkGold,
    background: colors().lightGold,
  },
  "& .sidebarIcon": {
    height: "20px",
    placeItems: "center",
    marginRight: "8px",
    marginTop: "-4px",
    "&.selected": {
      "& img": {
        filter: 'brightness(0) saturate(100%) invert(49%) sepia(68%) saturate(409%) hue-rotate(1deg) brightness(96%) contrast(101%)'
      }
    }
  },
}));

const MobileMenuItemWrapper = styled(Box)(({ theme }) => ({
  "& .sb-userinfo-b": {
    width: "100%",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F9FAF9",
  },
  "& .sb-userinfo-b-buyer": {
    textAlign: "center",
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: "420",
    color: colors().ultraDarkGreen,
    textTransform: 'uppercase',
    marginTop: "5px",
    wordBreak: 'break-all'
  },
  "& .sb-userinfo-b-supplier": {
    textAlign: "center",
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: "420",
    color: "#ACACAC",
    textTransform: 'uppercase',
    marginTop: "5px",
    wordBreak: 'break-all'
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 14,
  padding: 0,
  margin: "0 10px",
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors().darkGold,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: colors().darkGold,
    boxSizing: 'border-box',
  },
}));

export default (NavbarBeforeLogin)