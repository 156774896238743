Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
  exports.getCompanyDetailsApiEndpoint = "bx_block_invoice/companies/get_company";
  exports.createCompanyDetailsEndpoint = "bx_block_invoice/companies";
  exports.updateCompanyDetailsEndpoint = "bx_block_invoice/companies/update_company";
  
exports.title = "SETTINGS";

exports.settingTabs= [
  {
      title: "Personal Details",
      link: "PersonalDetails"
  },
  {
      title: "Company Details",
      link: "CompanyDetails"
  },
  {
      title: "Shipping Address",
      link: "ShippingAddress"
  },
  {
      title: "Payment Details",
      link: "PaymentDetails"
  },
  {
      title: "User Management",
      link: "UserManagement"
  },
  {
      title: "Subscription Plans",
      link: "SubscriptionPlans"
  },
  {
    title: "Legals",
    link: "Legals"
  }
];
exports.first_name_text_profile = "First name"
exports.first_name_placeholder = "User name"

exports.last_name_text_profile = "Surname"
exports.last_name_placeholder = "User surname"

exports.email_text_profile = "Work email"
exports.email_placeholder = "User working email eg. johndoe@companyname.com"

exports.job_title_text_profile = "Job title"
exports.job_title_placeholder = "User job title"

exports.company_name_text_profile = "Company name"
exports.company_name_placeholder = "Company-name"

exports.country_text_profile = "Country"
exports.country_placeholder = "Select country"

exports.city_text_profile = "City"
exports.city_placeholder = "Select city"

exports.phone_number_text_profile = "Phone number"
exports.phone_number_placeholder = "XXX XXX XXXX"

exports.company_name_text = "Name of the company"
exports.company_name_Placeholder = "Write name of your company"

exports.company_name_registered_text = "Company registered address"
exports.company_name_registered_first = "House or Building Number and Street Name"
exports.company_name_registered_two = "Adress information"

exports.city_text = "City"
exports.city_Placeholder = "Name of the city"

exports.phone_pstal_code_text = "Postal code"
exports.postal_code_Placeholder = "XXX XXX"

exports.country_text = "Country"
exports.country_text_Placeholder = "Name of the country"

exports.phone_number_text = "Phone number"
exports.phone_number_Placeholder = "000 000 000"
exports.company_detail_text = "Company details"

exports.getProfileDetailsEndpoint = "account/accounts/logged_user"
exports.updateProfileDetailsEndpoint = "account/update"
exports.updatePasswordEndpoint = "account/accounts/change_password"
exports.invalidFileError = "Invalid file format"
exports.formSuccessMsg = "Changes has been saved successfully"

exports.countryList = [
  {
    label: "United Kingdom",
    value: "United Kingdom",
    country_code: "+44"
  },
  {
    label: "Germany",
    value: "Germany",
    country_code: "+49"
  },
  {
    label: "Spain",
    value: "Spain",
    country_code: "+34"
  },
  {
    label: "France",
    value: "France",
    country_code: "+33"
  },
  {
    label: "Italy",
    value: "Italy",
    country_code: "+39"
  },
  {
    label: "Portugal",
    value: "Portugal",
    country_code: "+351"
  },
]

exports.countryList = [
  {
    label: "United Kingdom",
    value: "United Kingdom"
  },
  {
    label: "Germany",
    value: "Germany"
  },
  {
    label: "Spain",
    value: "Spain"
  },
  {
    label: "France",
    value: "France"
  },
  {
    label: "Italy",
    value: "Italy"
  },
  {
    label: "Portugal",
    value: "Portugal"
  },
]

// Customizable Area End
