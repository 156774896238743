import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import {getActivePath, onHandleNavigation} from "../../../blocks/utilities/src/CommonFunctions";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isSupplier: boolean;
  activePath: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationWrapperController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isSupplier: false,
      activePath: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
        const authToken = await getStorageData("accessToken");
       this.setState({ token: authToken });
        this.checkActivePath();
  }

  checkActivePath = () => {
    this.setState({ activePath: getActivePath(this.props) })
  }

  onHandleNavigation = (path: string, secondPath?:string) => {
    if (path) {
      this.send(onHandleNavigation(this.props, path, secondPath))
    }
  };

  onHandleBuyerSupplier = (isSupplier: boolean) => {
    this.setState({ isSupplier })
  };  

  onHandleLogout = () => {
    removeStorageData("accessToken")
    this.onHandleNavigation("EmailAccountLoginBlock")
  };

  // Customizable Area End
}
