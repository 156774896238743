import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  TextField,
  Grid,
  InputLabel,
  Avatar,
  Button,
  Container,
  IconButton,
  MenuItem,
  FormHelperText,
  InputAdornment
} from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Formik } from "formik";
import { styled } from '@mui/material/styles';
import PersonalDetailsController, {
  Props,
  configJSON,
} from "./PersonalDetailsController.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import { allowNumberWithSpaceValidation, handleErrors, handleHelperText } from "../../../blocks/utilities/src/CommonFunctions";
import { PersonalDetailsSchema, updatePasswordSchema } from "../../../blocks/utilities/src/validationSchema";
import { IconAddphoto, DeleteImg } from "./assets";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// Customizable Area End


export class PersonalDetails extends PersonalDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { profilePicture, showCurrentPassword, showNewPassword, showConfirmNewPassword, userInfo } = this.state;
    return (

      <MainContainer>
        <Box className="innerContainer">
          <Formik
            enableReinitialize={true}
            validateOnBlur={true}
            initialValues={this.setFormikInitialValues()}
            validationSchema={PersonalDetailsSchema}
            data-test-id="formik"
            onSubmit={this.editProfile}
          >
            {({ errors, touched, handleChange, handleSubmit, setFieldValue, handleReset, values }) => (
              <form onSubmit={handleSubmit}>
                <Box className="headerContainer">
                  <Box className="userAvatar">
                    <Box className="userAvatarContainer">
                      <Avatar className="avatarName" src={profilePicture} alt={this.shortName()}>{this.shortName()}</Avatar>
                    </Box>
                  </Box>
                  <Box className="headerDetils">
                    <Typography className='headerName'>{userInfo.first_name + " " + userInfo.last_name}</Typography>
                    <Typography className='headerEmail'>{userInfo.email}</Typography>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      className="pictureUploader"
                    >
                      <img src={IconAddphoto} /> {profilePicture ? "REPLACE PHOTO" : "ADD PHOTO"}
                      <ImageUploader
                        type="file"
                        InputProps={{
                          inputProps: {
                            accept: "image/*"
                          },
                        }}
                        name="account_image"
                        data-test-id="image_uploader"
                        onChange={(e: any) => { this.handleProfileChange(e, setFieldValue) }}
                      />
                    </Button>
                    {profilePicture &&
                      <Button
                        component="label"
                        role={undefined}
                        tabIndex={-1}
                        className="pictureUploader"
                        data-test-id="clear_image"
                        onClick={this.handleClearProfileImg.bind(null, setFieldValue)}
                      >
                        <img src={DeleteImg} /> CLEAR
                      </Button>
                    }
                  </Box>
                </Box>

                <ProfileFormContainer>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <StyledTextField>
                        <InputLabel className={"inputLabel"}>
                          {configJSON.first_name_text_profile}
                        </InputLabel>
                        <TextField
                          data-test-id="first_name"
                          placeholder={configJSON.first_name_placeholder}
                          name={"first_name"}
                          type="text"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.first_name}
                          fullWidth
                          error={handleErrors(
                            errors.first_name,
                            touched.first_name
                          )}
                          className={"inputField"}
                          helperText={handleHelperText(
                            errors.first_name,
                            touched.first_name
                          )}
                        />
                      </StyledTextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledTextField>
                        <InputLabel data-test-id="last_name" className={"inputLabel"}>
                          {configJSON.last_name_text_profile}
                        </InputLabel>
                        <TextField
                          placeholder={configJSON.last_name_placeholder}
                          name={"last_name"}
                          type="text"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.last_name}
                          fullWidth
                          error={handleErrors(
                            errors.last_name,
                            touched.last_name
                          )}
                          className={"inputField"}
                          helperText={handleHelperText(
                            errors.last_name,
                            touched.last_name
                          )}
                        />
                      </StyledTextField>
                    </Grid>

                    <Grid item xs={12}>
                      <StyledTextField>
                        <InputLabel data-test-id="email" className={"inputLabel"}>
                          {configJSON.email_text_profile}
                        </InputLabel>
                        <TextField
                          placeholder={configJSON.email_placeholder}
                          name={"email"}
                          type="email"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.email}
                          fullWidth
                          error={handleErrors(
                            errors.email,
                            touched.email
                          )}
                          className={"inputField"}
                          helperText={handleHelperText(
                            errors.email,
                            touched.email
                          )}
                        />
                      </StyledTextField>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <StyledTextField>
                        <InputLabel data-test-id="job_title" className={"inputLabel"}>
                          {configJSON.job_title_text_profile}
                        </InputLabel>
                        <TextField
                          placeholder={configJSON.job_title_placeholder}
                          name={"job_title"}
                          type="text"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.job_title}
                          fullWidth
                          error={handleErrors(
                            errors.job_title,
                            touched.job_title
                          )}
                          className={"inputField"}
                          helperText={handleHelperText(
                            errors.job_title,
                            touched.job_title
                          )}
                        />
                      </StyledTextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledTextField>
                        <InputLabel data-test-id="company_name" className={"inputLabel"}>
                          {configJSON.company_name_text_profile}
                        </InputLabel>
                        <TextField
                          placeholder={configJSON.company_name_placeholder}
                          name={"company_name"}
                          type="text"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.company_name}
                          fullWidth
                          disabled={true}
                          error={handleErrors(
                            errors.company_name,
                            touched.company_name
                          )}
                          className={"inputField"}
                          helperText={handleHelperText(
                            errors.company_name,
                            touched.company_name
                          )}
                        />
                      </StyledTextField>
                    </Grid>

                    <Grid item xs={12} sm={3.5}>
                      <StyledTextField>
                        <InputLabel className={"inputLabel"}>
                          {configJSON.country_text_profile}
                        </InputLabel>
                        <TextField
                          data-test-id="country"
                          placeholder={configJSON.country_placeholder}
                          name={"country"}
                          select
                          variant="outlined"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCountryChange(setFieldValue, event)}
                          value={values.country}
                          defaultValue={""}
                          fullWidth
                          error={handleErrors(
                            errors.country,
                            touched.country
                          )}
                          className={"inputField"}
                          helperText={handleHelperText(
                            errors.country,
                            touched.country
                          )}
                          SelectProps={{
                            IconComponent: KeyboardArrowDownIcon,
                          }}
                        >
                          <MenuItem value={""} disabled style={{ color: "#64748B" }}>
                            Country Name
                          </MenuItem>
                          {configJSON.countryList.map((item: { label: string, value: string }) => (
                            <MenuItem
                              key={item.value}
                              value={item.value}
                            >
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </StyledTextField>
                    </Grid>
                    <Grid item xs={12} sm={3.5}>
                      <StyledTextField>
                        <InputLabel data-test-id="city" className={"inputLabel"}>
                          {configJSON.city_text_profile}
                        </InputLabel>
                        <TextField
                          placeholder={configJSON.city_placeholder}
                          name={"city"}
                          type="text"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.city}
                          fullWidth
                          error={handleErrors(
                            errors.city,
                            touched.city
                          )}
                          className={"inputField"}
                          helperText={handleHelperText(
                            errors.city,
                            touched.city
                          )}
                        />
                      </StyledTextField>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <StyledTextField>
                        <InputLabel data-test-id="phone_number" className={"inputLabel"}>
                          {configJSON.phone_number_text_profile}
                        </InputLabel>
                          <TextField
                            placeholder={configJSON.phone_number_placeholder}
                            name={"phone_number"}
                            type="text"
                            variant="outlined"
                            onChange={handleChange}
                            value={values.phone_number}
                            fullWidth
                            onKeyPress={
                              allowNumberWithSpaceValidation
                            }
                            data-test-id="phone_number"
                            className={"inputField phoneNumberField"}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{margin: "0"}}
                                >
                                  {values.country_code &&
                                    <span className="country_code_span">
                                      {values.country_code}
                                    </span>
                                  }
                                </InputAdornment>
                              ),
                            }}
                            error={handleErrors(
                              errors.phone_number,
                              touched.phone_number
                            ) || handleErrors(
                              errors.country_code,
                              touched.country_code
                            )}
                          />
                        {(handleErrors(errors.phone_number, touched.phone_number) ||
                          handleErrors(errors.country_code, touched.country_code)) && (
                            <FormHelperText
                              data-test-id={"errorMessage"}
                              className={"helperText"}
                            >
                              {
                                handleHelperText(errors.country_code, touched.country_code) ??
                                handleHelperText(errors.phone_number, touched.phone_number)
                              }
                            </FormHelperText>
                          )}
                      </StyledTextField>
                    </Grid>
                  </Grid>
                </ProfileFormContainer>

                <ButtonContainer>
                  <Button
                    disabled={this.checkFormDisabled(values)}
                    className="cancelBtn"
                    data-test-id="cancel_button"
                    onClick={this.onResetForm.bind(this, handleReset)}
                  >
                    Cancel
                  </Button>
                  <Box className={`saveBtnOuter ${this.checkFormDisabled(values) && "disabled"}`}>
                    <Button
                      disabled={this.checkFormDisabled(values)}
                      className='saveBtn'
                      type="submit"
                    >
                      Save Changes
                    </Button>
                  </Box>
                </ButtonContainer>
              </form>
            )}
          </Formik>

          <Formik
            enableReinitialize={true}
            validateOnBlur={true}
            initialValues={this.setPasswordInitialValues()}
            validationSchema={updatePasswordSchema}
            data-test-id="password-formik"
            onSubmit={this.updatePassword}
          >
            {({ errors, touched, setFieldValue, handleBlur, handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <ProfileFormContainer className="secondInfoBox">
                  <Typography className='passwordTitle'>Password</Typography>
                  <Typography className='changePasswordTitle'>Change your password</Typography>

                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <StyledTextField>
                        <InputLabel className={"inputLabel passWordInputLabel"}>
                          Your current password
                        </InputLabel>
                        <TextField
                          data-test-id="current_password"
                          placeholder={"******"}
                          name={"current_password"}
                          type={showCurrentPassword ? "password" : "text"}
                          variant="outlined"
                          fullWidth
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handlePasswordChange(setFieldValue, event)}
                          value={values.current_password}
                          onBlur={handleBlur}
                          className={"inputField removeBorder"}
                          error={handleErrors(
                            errors.current_password,
                            touched.current_password
                          )}
                          helperText={handleHelperText(
                            errors.current_password,
                            touched.current_password
                          )}
                          InputProps={{
                            endAdornment:
                              <IconButton
                                aria-label="current_password"
                                data-test-id="current_password-eye"
                                onClick={() => this.handleViewHidePassword("showCurrentPassword")}
                                edge="end"
                                disableRipple={true}
                              >
                                {showCurrentPassword ? <VisibilityOutlinedIcon className="eyeIcon" /> : <VisibilityOffOutlinedIcon className="eyeIcon" />}
                              </IconButton>
                          }}
                        />
                      </StyledTextField>
                    </Grid>

                    <Grid item xs={12}>
                      <StyledTextField>
                        <InputLabel className={"inputLabel passWordInputLabel"}>
                          New password
                        </InputLabel>
                        <TextField
                          placeholder={"******"}
                          name={"new_password"}
                          data-test-id="new_password"
                          type={showNewPassword ? "password" : "text"}
                          variant="outlined"
                          fullWidth
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handlePasswordChange(setFieldValue, event)}
                          value={values.new_password}
                          error={handleErrors(
                            errors.new_password,
                            touched.new_password
                          )}
                          className={"inputField removeBorder"}
                          helperText={handleHelperText(
                            errors.new_password,
                            touched.new_password
                          )}
                          onBlur={handleBlur}
                          InputProps={{
                            endAdornment:
                              <IconButton
                                aria-label="new_password"
                                data-test-id="new_password-eye"
                                data-testid="new_password-eye"
                                onClick={() => this.handleViewHidePassword("showNewPassword")}
                                edge="end"
                                disableRipple={true}
                              >
                                {showNewPassword ? <VisibilityOutlinedIcon className="eyeIcon" /> : <VisibilityOffOutlinedIcon className="eyeIcon" />}
                              </IconButton>
                          }}
                        />
                      </StyledTextField>
                    </Grid>

                    <Grid item xs={12}>
                      <StyledTextField>
                        <InputLabel className={"inputLabel passWordInputLabel"}>
                          Confirm new password
                        </InputLabel>
                        <TextField
                          placeholder={"******"}
                          data-test-id="confirm_new_password"
                          name={"confirm_new_password"}
                          type={showConfirmNewPassword ? "password" : "text"}
                          variant="outlined"
                          fullWidth
                          value={values.confirm_new_password}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handlePasswordChange(setFieldValue, event)}
                          onBlur={handleBlur}
                          error={handleErrors(
                            errors.confirm_new_password,
                            touched.confirm_new_password
                          )}
                          className={"inputField removeBorder"}
                          helperText={handleHelperText(
                            errors.confirm_new_password,
                            touched.confirm_new_password
                          )}
                          InputProps={{
                            endAdornment:
                              <IconButton
                                aria-label="confirm_new_password"
                                data-test-id="confirm_new_password-eye"
                                onClick={() => this.handleViewHidePassword("showConfirmNewPassword")}
                                edge="end"
                                disableRipple={true}
                              >
                                {showConfirmNewPassword ? <VisibilityOutlinedIcon className="eyeIcon" /> : <VisibilityOffOutlinedIcon className="eyeIcon" />}
                              </IconButton>
                          }}
                        />
                      </StyledTextField>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        disabled={this.checkPasswordFormDisabled(values)}
                        className='changePasswordButton'
                        type="submit"
                      >
                        Change password
                      </Button>
                    </Grid>
                  </Grid>
                </ProfileFormContainer>

                <ButtonContainer>
                </ButtonContainer>
              </form>
            )}
          </Formik>
        </Box>
      </MainContainer>

    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainContainer = styled(Container)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  backgroundColor: colors().white,
  paddingTop: "85px",

  '@media (max-width: 768px)': {
    paddingTop: '35px',
  },
  '@media (max-width: 480px)': {
    paddingTop: '7px',
  },

  "& .innerContainer": {
    maxWidth: "603px",
    width: "100%",
    maxWwidth: "603px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  "& .headerContainer": {
    width: "100%",
    height: "auto",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    gap: "30px",
    '@media (max-width: 480px)': {
      gap: "20px",
      flexDirection: "Column",
    },
  },
  "& .userAvatar": {
    background: "linear-gradient(180deg, rgba(183, 134, 43, 0) 0%, #B7862B 100%)",
    padding: "1px",
    transform: "rotate(60deg)",
    width: '134px',
    height: '134px',
    margin: '0px',
    borderRadius: '50%',
    backgroundColor: colors().lightGold,
    textAlign: 'center',
    '@media (max-width: 480px)': {
      width: '115px',
      height: '115px',
    },
  },
  "& .userAvatarContainer": {
    width: "calc(100% - 10px)",
    height: "calc(100% - 10px)",
    borderRadius: 'inherit',
    backgroundColor: colors().white,
    padding: "5px",
  },
  "& .avatarName": {
    width: "100%",
    height: "100%",
    backgroundColor: colors().lightGold,
    color: "#BA9553",
    fontSize: "52px",
    fontWeight: 420,
    lineHeight: "72px",
    transform: "rotate(-60deg)",
    '@media (max-width: 480px)': {
      fontSize: "44.78px",
    },
  },
  "& .headerDetils": {
    maxWidth: "calc(100% - 190px)",
    '@media (max-width: 480px)': {
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  "& .headerName": {
    color: '#616161',
    fontSize: '24px',
    fontWeight: 420,
    lineHeight: '32px',
    textTransform: "uppercase",
    wordBreak: "break-all",
    '@media (max-width: 480px)': {
      fontSize: '20px',
      lineHeight: '28px',
    },
  },
  "& .headerEmail": {
    color: '#78716C',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19.2px',
    wordBreak: "break-all",
    fontFamily: "garamond-premier-pro !important"
  },
  "& .pictureUploader": {
    marginTop: "14.11px",
    color: colors().darkGold,
    height: "18px",
    paddingLeft: "0px",
    paddingRight: "0px",
    marginRight: "18.11px",
    fontSize: '12px',
    fontWeight: 420,
    lineHeight: '17.16px',
    "& img": {
      marginRight: "8px",
      fontSize: '12px',
      fontWeight: 420,
    },
    '@media (max-width: 480px)': {
      marginTop: "10.11px",
    },
  }
});

const ImageUploader = styled(TextField)({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const ProfileFormContainer = styled(Container)({
  width: "100%",
  height: "auto",
  backgroundColor: colors().white,
  marginTop: "55px",
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  padding: '28px 32px 32px 32px',
  border: '1px solid #C6C6C6',

  '@media (max-width: 768px)': {
    marginTop: "50px",
  },

  '@media (max-width: 480px)': {
    marginTop: "40px",
  },

  "&.secondInfoBox": {
    marginTop: "13px",
  },

  "& .phoneWrapper": {
    display: "flex",
    gap: "5px",
    "& .countryCode": {
      width: "35%",
    }
  },

  "& .passwordTitle": {
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    color: "#1F2024",
    fontFamily: "garamond-premier-pro !important"
  },
  "& .changePasswordTitle": {
    fontSize: "16px",
    fontWeight: 420,
    lineHeight: "24px",
    color: "#424242",
    margin: "16px 0",
  },
  "& .changePasswordButton": {
    width: '190px',
    height: '32px',
    marginTop: "24px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWwight: 400,
    color: colors().whiteText,
    backgroundColor: colors().black,
    borderRadius: "36px",
    textTransform: "none",
  },
});

const StyledTextField = styled(Box)({
  margin: "0px",
  position: "relative" as any,
  "& .helperText": {
    margin: 0,
    fontSize: "14px",
    lineHeight: "18px",
    color: `${colors().error} !important`,
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#F8FAFC",
    borderRadius: "0",
    borderColor: "#E2E8F0",
    height: "36px",
    paddingRight: "12.5px",
    "& .MuiInputBase-input": {
      fontSize: "14px",
      fontWeight: 390,
      lineHeight: "22px",
      color: colors().blackText,
      padding: "8px 12px",
      paddingRight: "0px",
      "& .Mui-disabled": {
        color: colors().text,
        backgroundColor: "#F5F5F5",
      },
    }
  },

  "& .inputLabel": {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 390,
    marginBottom: "5px",
    color: colors().blackText,
  },
  "& .passWordInputLabel": {
    color: "#424242",
  },
  "& .inputField": {
    marginTop: "10px",
    margin: 0,
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderColor: colors().blackText,
      },
    },
    "& .MuiFormHelperText-root": {
      margin: 0,
      fontSize: "14px",
      lineHeight: "18px",
      color: `${colors().error} !important`,
    },
    "& .Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${colors().error} !important`,
      }
    },

    "& .MuiSelect-icon": {
      color: "#44403C",
    },
    "& .eyeIcon": {
      cursor: "pointer",
      color: "#94A3B8",
      height: '16px',
      width: "16px"
    }
  },
  "& .removeBorder": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F8FAFC",
    },
  },
  "& .phoneNumberField": {
    "& input": {
      paddingLeft: "0px !important",
    },
    "& .country_code_span": {
      fontSize: "14px !important",
      fontWeight: 390,
      lineHeight: "22px !important",
      color: colors().blackText,
      paddingRight: "12px !important",
    },
  },
});

const ButtonContainer = styled(Container)(() => {
  return {
    backgroundColor: colors().white,
    padding: "24px",
    alignContent: "right",
    display: "flex",
    justifyContent: "end",

    '@media (max-width: 480px)': {
      flexWrap: 'wrap',
      paddingTop: "20px",
      padding: "12px",
    },

    "& .cancelBtn": {
      width: "auto",
      height: "56px",
      color: colors().darkGold,
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 420,
      alignItems: "baseline",
      alignContent: "center",
      padding: "16px 0px 16px 0px",
      textTransform: "none",
      "&.Mui-disabled": {
        color: "#94A3B8",
      },
      '@media (max-width: 480px)': {
        height: "46px",
      }
    },
    "& .saveBtn": {
      width: "100%",
      height: "100%",
      color: colors().darkGold,
      backgroundColor: "transparent",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 420,
      borderRadius: "inherit",
      "&.Mui-disabled": {
        backgroundColor: "#F1F5F9",
        color: "#64748B",
      },
    },
    "& .saveBtnOuter": {
      width: "183px",
      height: "56px",
      marginLeft: "28px",
      background: "linear-gradient(#fff 0 0) padding-box, linear-gradient(99.09deg, #D8C487 2.64%, #B7862B 100%) border-box",
      border: "1px solid transparent",
      borderRadius: "36px",
      display: "inline-block",
      "&.disabled": {
        background: "#F1F5F9",
      },
      '@media (max-width: 480px)': {
        marginLeft: "15px",
        width: "149px",
        height: "46px",
      },
    },
  }

}
);
// Customizable Area End
