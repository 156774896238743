import React from "react";

// Customizable Area Start
import {
  Box,
} from "@mui/material";
import {  styled } from '@mui/material/styles';

import NavigationWrapperController, {
  Props,
  configJSON
} from "./NavigationWrapperController.web";
import NavbarBeforeLogin from "../../../components/src/NavbarBeforeLogin.web";
import SidebarAfterLogin from "../../../components/src/SidebarAfterLogin.web";
import Footer from "../../../components/src/Footer.web";
// Customizable Area End


export class NavigationWrapper extends NavigationWrapperController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {token, activePath} = this.state
    return (

      <NavbarWrapper>
        <NavbarBeforeLogin 
          configJSON={configJSON} 
          data-test-id="side-bar"
          navigation={this.props.navigation}
          token={token}
          activePath={activePath}
          onHandleLogout={this.onHandleLogout}
        />
        <Box className="mainContainer">
          {token && <SidebarAfterLogin 
            data-test-id="commonSidebar"
            configJSON={configJSON} 
            navigation={this.props.navigation}
            onHandleNavigation={this.onHandleNavigation}
            onHandleBuyerSupplier={this.onHandleBuyerSupplier}
            onHandleLogout={this.onHandleLogout}
            isSupplier={this.state.isSupplier}
            activePath={activePath}
          />}
          {this.props.children}
        </Box>

        <Footer 
          data-test-id="commonFotter"
          onHandleNavigation={this.onHandleNavigation} 
          configJSON={configJSON}
        />
      </NavbarWrapper>

    );
    // Customizable Area End
  }
}

// Customizable Area Start

const NavbarWrapper = styled(Box)(({ theme }) => ({
  height: "100vh",
  flexDirection: "column" as const,
  justifyContent: "space-between",
  display: "flex",
  "& .mainContainer" : {
    flex: 1,
    display: "flex",
  }
}));
// Customizable Area End
